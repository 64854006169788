import React, { useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import {
  Form,
  Input,
  Box,
  Message,
  displayErrorMessage,
  Field,
  theme,
} from "@clearabee/ui-library";
import { isValidInternationalNumber } from "helpers";

/**
 * Import components.
 */
import { InputInternationalPhoneNumber } from "components/common/components";
import { NoContactDetailsSwitch, PONumberField } from "../components";
import { ImageUpload } from "../../../core";
/**
 * Import hooks.
 */
import {
  useAuthContext,
  useBasketContext,
  useCatalogueContext,
  useMultiFormContext,
} from "../../../../hooks";

/**
 * Import types.
 */
import {
  getItemFromCatalogueBySku,
  getDeliveryOptions,
  getDeliveryOptionsForDropdown,
  getDeliveryOptionChargeFromOption,
} from "../../../../helpers/catalogue";
import { ICreateJobFormState } from "../createJob";

import { getCollectionDetailsSchema } from "../validation";
import roles from "../../../../constants/roles";

type OptionsProps = {
  label: string;
  value: string;
};

/**
 * Interface.
 */
interface IImage {
  file: File;
  dataUrl: string;
}

export const CollectionDetails = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { doesUserHaveRole } = useAuthContext();
  const isAdmin = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const { catalogueItems } = useCatalogueContext();
  const { hasItem, deleteItem, addItem, items } = useBasketContext();
  const permitCharge = getItemFromCatalogueBySku(
    catalogueItems || [],
    "SKIPPERMIT",
  );

  /**
   * Selected item
   */
  const selectedCatalogueItem = getItemFromCatalogueBySku(
    catalogueItems || [],
    items.length && items[0].sku ? items[0].sku : "",
  );

  /**
   * Delivery options
   */
  const deliveryOptions = getDeliveryOptions(selectedCatalogueItem);
  const { timeslotPreferenceOptions } =
    getDeliveryOptionsForDropdown(deliveryOptions);

  // start from 8am to 6pm
  const customTimeslotOptions = Array.from({ length: 11 }, (_, i) => {
    if (i + 8 === 18) return { label: "18:00:00", value: 18 };

    return Array.from({ length: 4 }, (_, j) => ({
      label: `${(i + 8).toString().padStart(2, "0")}:${(j * 15)
        .toString()
        .padStart(2, "0")}:00`,
      value: i + 8 + j * 0.25,
    }));
  }).flatMap((option) => option);

  const {
    formState: {
      accessInformation,
      collectionAddress,
      customer: {
        phoneNumber: customerPhone,
        email: customerEmail,
        firstName,
        lastName,
      },
      meta: { poNumber, wasteType, zendeskTicketId, what3words },
      noContactDetails,
      timeslotPreference,
      isCustomTimeslot,
      customTimeslot,
      timeslot,
      wasteDescription,
      landType,
      company,
      reportsMetaValidation,
      sicCode,
      images: formStateImages,
      useAdhoc,
      companyOrderNumberRequired,
      orderNumberLabel,
      orderNumberValidation,
      orderNumberValidationMessage,
      contactDetailsRequired,
      phoneAndEmailRequired,
      reportsMeta,
      bigchangeProps: {
        cust_RiskAddressIsCollectionAddress,
        cust_RiskPostcode,
        "cust_Is the customer vulnerable": custIsCustomerVulnerable,
        "cust_VC Notes": custVcNotes,
      },
    },
    pushState,
    nextStep,
  } = useMultiFormContext<ICreateJobFormState>();

  const [t] = useTranslation("jobs");

  const isVT = false;
  const isSkip = hasItem("SKIPS", true);

  const companyCode = !!company?.value ? company.value : "";

  const handleSubmit = (state: any) => {
    const { email, firstName, lastName, phoneNumber } = state.customer;

    if (state && state.meta && state.meta.poNumber) {
      state.meta = {
        ...state.meta,
        poNumber: state.meta.poNumber.replace(/\s/g, ""),
      };
    }

    if (state && state.isCustomTimeslot === true) {
      const startTimeLabel = customTimeslotOptions.find(
        ({ value }) => value === state.customTimeslot.startTime,
      )?.label;
      const endTimeLabel = customTimeslotOptions.find(
        ({ value }) => value === state.customTimeslot.endTime,
      )?.label;

      state.timeslot = `${startTimeLabel}-${endTimeLabel}`;
    }

    pushState({
      ...state,
      formStateImages,
      customer: {
        email: email.replace(/\s/g, ""),
        phoneNumber: isValidInternationalNumber(phoneNumber) ? phoneNumber : "",
        firstName,
        lastName,
      },
    });
    nextStep();
  };

  /**
   * This sets the form values to be whatever is
   * within the formState at the present time.
   * If the form has not yet been filled out, these will
   * be blank. However if the form has been filled out, and then
   * returned to from a later step, it will prefill
   * the form with the values that were entered in the first time
   * around. This provides a good UX should the user want
   * to edit details they have already entered once.
   */
  const initialValues = {
    accessInformation: accessInformation,
    customer: {
      email: customerEmail ?? "",
      firstName,
      lastName,
      phoneNumber: customerPhone ?? "",
    },
    images: formStateImages || [],
    meta: {
      poNumber,
      wasteType,
      zendeskTicketId,
      what3words,
    },
    noContactDetails: noContactDetails || false,
    timeslot,
    timeslotPreference,
    isCustomTimeslot,
    customTimeslot,
    wasteDescription: wasteDescription,
    landType: isSkip ? landType : "",
    wasteTypes: wasteType
      ? wasteType.split(",").map((eachWasteType) => {
          return { value: eachWasteType, label: eachWasteType };
        })
      : null,
    sicCode,
    reportsMeta: reportsMetaValidation?.reduce((acc, report) => {
      acc[report.name] = "";
      return acc;
    }, {} as Record<string, string>),
    bigchangeProps: {
      cust_RiskAddressIsCollectionAddress,
      cust_RiskPostcode: cust_RiskAddressIsCollectionAddress
        ? cust_RiskPostcode
        : "",
      "cust_Is the customer vulnerable": custIsCustomerVulnerable,
      "cust_VC Notes": custIsCustomerVulnerable ? custVcNotes : "",
    },
  };

  /**
   * Clear any delivery charge on mount of this step.
   * As user has to select new timeslot each time.
   */
  useEffect(() => {
    deleteItem("DELIVERYCHARGE");
  }, []);

  return (
    <Form
      initialValues={initialValues}
      validationSchema={getCollectionDetailsSchema({
        /**
         * The validationSchema will vary for this form, dependent
         * on which product the user has selected and which collection
         * options are available for those products. This is passing the booleans
         * of which prop is true
         */
        isSkip,
        hasTimeslots: deliveryOptions !== null && deliveryOptions.length > 0,
        orderNumberRequired: companyOrderNumberRequired,
        requirePhoneAndEmail: phoneAndEmailRequired,
        orderNumberValidation: orderNumberValidation ?? "",
        orderNumberValidationMessage: orderNumberValidationMessage ?? "",
        reportsMeta: reportsMetaValidation,
      })}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnBlur
    >
      {({
        setFieldValue,
        validateField,
        isValid,
        values,
        errors,
        submitCount,
      }) => {
        useEffect(() => {
          validateField("timeslotPreference");
        }, [values.timeslotPreference]);

        useEffect(() => {
          if (!values.bigchangeProps.cust_RiskAddressIsCollectionAddress) {
            setFieldValue("bigchangeProps.cust_RiskPostcode", "");
          }
        }, [values.bigchangeProps.cust_RiskAddressIsCollectionAddress]);

        return (
          <>
            <Box className="bg-white w-full shadow-filter min-h-64 px-5 py-10 rounded-xl">
              <Box className="md:flex md:flex-row py-2 md:py-6">
                <Box className="w-full md:w-2/5 lg:w-1/4 px-2 lg:px-4">
                  <h4 className="text-primary font-semibold">
                    {t("create.steps.collectionDetails.titles.collection")}
                  </h4>
                  {!useAdhoc && (
                    <PONumberField
                      label={orderNumberLabel}
                      postcode={collectionAddress.postcode}
                      companyCode={companyCode}
                      required={companyOrderNumberRequired}
                    />
                  )}
                  {isAdmin && !useAdhoc && (
                    <Field
                      name="meta.zendeskTicketId"
                      label={t(
                        "create.steps.collectionDetails.form.labels.zendeskTicketId",
                      )}
                    >
                      {({ field }) => (
                        <Input.Text
                          {...field}
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.zendeskTicketId",
                          )}
                        />
                      )}
                    </Field>
                  )}
                  <Field
                    name="sicCode"
                    label={t(
                      "create.steps.collectionDetails.form.labels.sicCode",
                    )}
                  >
                    {({ field }) => (
                      <Input.Text
                        {...field}
                        placeholder={t(
                          "create.steps.collectionDetails.form.placeholders.sicCode",
                        )}
                      />
                    )}
                  </Field>
                  {!!deliveryOptions?.length && (
                    <Field
                      name="timeslotPreference"
                      label={`${t(
                        "create.steps.collectionDetails.form.labels.timeslotPreference",
                      )}*`}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.timeslotPreference",
                          )}
                          isDisabled={values.isCustomTimeslot}
                          options={timeslotPreferenceOptions}
                          value={
                            timeslotPreferenceOptions?.find(
                              ({ value }) =>
                                value === values.timeslotPreference,
                            ) || null
                          }
                          isClearable={true}
                          isSearchable={true}
                          isMulti={false}
                          onChange={(option: OptionsProps) => {
                            setFieldValue("customTimeslot.startTime", "");
                            setFieldValue("customTimeslot.endTime", "");
                            setFieldValue("customTimeslot.charge", 0);

                            if (option !== null) {
                              setFieldValue("timeslot", "");
                              setFieldValue("timeslotPreference", option.value);

                              const charge = getDeliveryOptionChargeFromOption(
                                option,
                                deliveryOptions,
                              );
                              setFieldValue("timeslotCharge", charge);
                            } else {
                              setFieldValue("timeslotPreference", "");
                            }
                          }}
                        />
                      )}
                    </Field>
                  )}
                  {values.timeslotPreference &&
                    !!deliveryOptions?.find(
                      ({ title }) => title === values.timeslotPreference,
                    )?.range?.length && (
                      <Field
                        name="timeslot"
                        label={t(
                          "create.steps.collectionDetails.form.labels.timeslot",
                        )}
                      >
                        {({ field }) => (
                          <Input.Select
                            {...field}
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.timeslot",
                            )}
                            options={
                              getDeliveryOptionsForDropdown(
                                deliveryOptions.filter(
                                  ({ title }) =>
                                    title === values.timeslotPreference,
                                ),
                              ).timeslotOptions
                            }
                          />
                        )}
                      </Field>
                    )}
                  {/* CUSTOM TIMESLOT FOR CLEARABEE ADMIN AND STAFF ONLY */}
                  {isAdmin && (
                    <Field
                      name="isCustomTimeslot"
                      label={t(
                        "create.steps.collectionDetails.form.labels.isCustomTimeslot",
                      )}
                    >
                      {({ field }) => (
                        <Input.Toggle
                          {...field}
                          onClick={() => {
                            // reset custom timeslots fields, timeslotPreference, timeslot, timeslotCharge everytime toggle is on/off
                            setFieldValue("customTimeslot.startTime", "");
                            setFieldValue("customTimeslot.endTime", "");
                            setFieldValue("customTimeslot.charge", 0);
                            setFieldValue("timeslot", "");
                            setFieldValue("timeslotPreference", "");
                            setFieldValue("timeslotCharge", 0);

                            setFieldValue(
                              "isCustomTimeslot",
                              !values.isCustomTimeslot,
                            );
                          }}
                        />
                      )}
                    </Field>
                  )}

                  {!!values.isCustomTimeslot && (
                    <>
                      <Field
                        name="customTimeslot.startTime"
                        label={`${t(
                          "create.steps.collectionDetails.form.labels.customStartTime",
                        )}*`}
                      >
                        {({ field }) => (
                          <Input.Select
                            {...field}
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.customStartTime",
                            )}
                            options={customTimeslotOptions.filter(
                              ({ label }) => label !== "18:00:00",
                            )}
                            onChange={(option) => {
                              const value = option.target.value;

                              setFieldValue(
                                "customTimeslot.startTime",
                                !!value ? Number(value) : "",
                              );
                            }}
                          />
                        )}
                      </Field>
                      <Field
                        name="customTimeslot.endTime"
                        label={`${t(
                          "create.steps.collectionDetails.form.labels.customEndTime",
                        )}*`}
                      >
                        {({ field }) => (
                          <Input.Select
                            {...field}
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.customEndTime",
                            )}
                            disabled={!values?.customTimeslot?.startTime}
                            options={
                              !!values?.customTimeslot?.startTime
                                ? customTimeslotOptions.filter(
                                    ({ value }) =>
                                      Number(value) >
                                      Number(values.customTimeslot.startTime),
                                  )
                                : []
                            }
                            onChange={(option) => {
                              const value = option.target.value;

                              setFieldValue(
                                "customTimeslot.endTime",
                                !!value ? Number(value) : "",
                              );
                            }}
                          />
                        )}
                      </Field>
                      <Field
                        name="customTimeslot.charge"
                        label={t(
                          "create.steps.collectionDetails.form.labels.customCharge",
                        )}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.customCharge",
                            )}
                            prefix={"£"}
                            type="number"
                          />
                        )}
                      </Field>
                    </>
                  )}
                  {/* if companyCode starts with LV- */}
                  {!useAdhoc && !!companyCode && companyCode.includes("LV-") && (
                    <Box>
                      <Field
                        name="bigchangeProps.cust_RiskAddressIsCollectionAddress"
                        label={t(
                          "create.steps.collectionDetails.form.labels.isRiskAddressDifferentToCollectionAddress",
                        )}
                      >
                        {({ field }) => <Input.Toggle {...field} />}
                      </Field>
                      {values.bigchangeProps
                        .cust_RiskAddressIsCollectionAddress && (
                        <Field
                          name="bigchangeProps.cust_RiskPostcode"
                          label={`${t(
                            "create.steps.collectionDetails.form.labels.riskPostcode",
                          )}*`}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              placeholder={t(
                                "create.steps.collectionDetails.form.placeholders.riskPostcode",
                              )}
                            />
                          )}
                        </Field>
                      )}
                    </Box>
                  )}
                  {/* if companyCode starts with DIR- */}
                  {!useAdhoc && !!companyCode && companyCode.includes("DIR-") && (
                    <>
                      <Field
                        name="bigchangeProps.cust_Is the customer vulnerable"
                        label={t(
                          "create.steps.collectionDetails.form.labels.isCustomerVulnerable",
                        )}
                      >
                        {({ field }) => <Input.Toggle {...field} />}
                      </Field>
                      {values.bigchangeProps[
                        "cust_Is the customer vulnerable"
                      ] && (
                        <Field
                          name="bigchangeProps.cust_VC Notes"
                          label={`${t(
                            "create.steps.collectionDetails.form.labels.vcNotes",
                          )}*`}
                        >
                          {({ field }) => (
                            <Input.Textarea
                              {...field}
                              placeholder={t(
                                "create.steps.collectionDetails.form.placeholders.vcNotes",
                              )}
                              maxLength={499}
                            />
                          )}
                        </Field>
                      )}
                    </>
                  )}
                  {isSkip && (
                    <>
                      <h4 className="text-primary font-semibold mb-6">
                        Skip Hire
                      </h4>
                      <Field
                        name="landType"
                        label={t(
                          "create.steps.collectionDetails.form.labels.landType",
                        )}
                      >
                        {({ field }) => (
                          <Input.Select
                            {...field}
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.landType",
                            )}
                            options={[
                              {
                                value: "Private Land",
                                label: "Private Land",
                              },
                              {
                                value: "Public Land",
                                label: `Public Land${
                                  permitCharge !== null
                                    ? ` (+£${permitCharge.price || "0.00"})`
                                    : ""
                                }`,
                              },
                            ]}
                            onChange={(option) => {
                              const value = option.target.value;
                              /**
                               * On change, update the field in the Formik context as normal.
                               */
                              if (option !== null) {
                                setFieldValue("landType", value);
                                if (value === "Public Land") {
                                  // add permit charge
                                  if (permitCharge !== null) {
                                    addItem({
                                      sku: permitCharge.sku,
                                      quantity: 1,
                                      price: permitCharge.price || 0,
                                      title: permitCharge.title,
                                    });
                                  }
                                } else {
                                  deleteItem("SKIPPERMIT");
                                }
                              } else {
                                setFieldValue("landType", "");
                                deleteItem("SKIPPERMIT");
                              }
                            }}
                          />
                        )}
                      </Field>
                    </>
                  )}
                  <Box className="mt-6 md:mt-10">
                    <h4 className="text-primary font-semibold">
                      {t("create.steps.collectionDetails.titles.fullAddress")}
                    </h4>
                    <Box className="mt-2">
                      {collectionAddress.hasOwnProperty("line1") && (
                        <p>{collectionAddress.line1}</p>
                      )}
                      {collectionAddress.hasOwnProperty("line2") && (
                        <p>{collectionAddress.line2}</p>
                      )}
                      {collectionAddress.hasOwnProperty("city") && (
                        <p>{collectionAddress.city}</p>
                      )}
                      {collectionAddress.hasOwnProperty("county") && (
                        <p>{collectionAddress.county}</p>
                      )}
                      {collectionAddress.hasOwnProperty("postcode") && (
                        <p>{collectionAddress.postcode}</p>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box className="w-full mt-6 md:mt-0 md:w-1/3 lg:w-1/4 px-2 lg:px-4">
                  <h4 className="text-primary font-semibold">
                    {t("create.steps.collectionDetails.titles.customer")}
                  </h4>
                  {!contactDetailsRequired && (
                    <>
                      {!useAdhoc && (
                        <NoContactDetailsSwitch
                          name="noContactDetails"
                          label={t(
                            "create.steps.collectionDetails.form.labels.noContactDetails",
                          )}
                          styles={{
                            margin: "12px 0",
                          }}
                        />
                      )}
                    </>
                  )}
                  {values.noContactDetails === false && (
                    <>
                      <Field
                        name="customer.firstName"
                        label={t(
                          "create.steps.collectionDetails.form.labels.firstName",
                        )}
                      >
                        {({ field }) => (
                          <Input.Text {...field} placeholder="First Name" />
                        )}
                      </Field>
                      <Field
                        name="customer.lastName"
                        label={t(
                          "create.steps.collectionDetails.form.labels.lastName",
                        )}
                      >
                        {({ field }) => (
                          <Input.Text {...field} placeholder="Last Name" />
                        )}
                      </Field>

                      <Field
                        name="customer.phoneNumber"
                        label={t(
                          "create.steps.collectionDetails.form.labels.customerPhone",
                        )}
                      >
                        {({ field }) => (
                          <InputInternationalPhoneNumber {...field} />
                        )}
                      </Field>

                      <Field
                        name="customer.email"
                        label={t(
                          "create.steps.collectionDetails.form.labels.customerEmail",
                        )}
                      >
                        {({ field }) => (
                          <Input.Text
                            {...field}
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.customerEmail",
                            )}
                          />
                        )}
                      </Field>
                      {!isVT && (
                        <Box className="mt-6 text-xs bg-orange text-white px-2 py-1 rounded-md font-semibold">
                          {t(
                            `create.steps.collectionDetails.form.messages.${
                              phoneAndEmailRequired
                                ? "emailAndPhone"
                                : "emailOrPhone"
                            }`,
                          )}
                          <br />
                          <br />
                          {t(
                            "create.steps.collectionDetails.form.messages.internationalContactPhone",
                          )}
                          <br />
                          {t("table.headings.internationalContactPhone")}
                        </Box>
                      )}
                    </>
                  )}
                  {isVT && (
                    <Box className="mt-3">
                      <Field
                        name="customerNoEmail"
                        label={t(
                          "create.steps.collectionDetails.form.labels.customerNoEmail",
                        )}
                      >
                        {({ field }) => <Input.Toggle {...field} />}
                      </Field>
                    </Box>
                  )}
                </Box>

                <Box className="w-auto mt-6 md:mt-0 lg:w-1/2 px-2 lg:px-4">
                  <h4 className="text-primary font-semibold">
                    {t("create.steps.collectionDetails.titles.waste")}
                  </h4>
                  {!isVT && (
                    <Field
                      name="wasteTypes"
                      label={t(
                        "create.steps.collectionDetails.form.labels.wasteType",
                      )}
                    >
                      {({ field }) => (
                        <Select
                          {...field}
                          name="wasteTypes"
                          isMulti
                          isClearable
                          styles={{
                            control: (base) => ({
                              ...base,
                              ...theme.fontDefaults.small,
                              minHeight: theme.spacing.xlarge,
                              alignItems: "center",
                              justifyContent: "center",
                            }),
                          }}
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.wasteType",
                          )}
                          options={[
                            {
                              value: "Hazardous Waste (Consignment*)",
                              label: "Hazardous Waste (Consignment*)",
                            },
                            {
                              value: "Paper & Cardboard (20 01 01)",
                              label: "Paper & Cardboard (20 01 01)",
                            },
                            {
                              value: "Glass (20 01 02)",
                              label: "Glass (20 01 02)",
                            },
                            {
                              value: "Non Hazardous WEEE (16 02 14)",
                              label: "Non Hazardous WEEE (16 02 14)",
                            },
                            {
                              value: "Bulky Waste (20 03 07)",
                              label: "Bulky Waste (20 03 07)",
                            },
                            {
                              value: "Wood (17 02 01)",
                              label: "Wood (17 02 01)",
                            },
                            {
                              value: "Food Waste (20 01 08)",
                              label: "Food Waste (20 01 08)",
                            },
                            {
                              value: "Construction (17 09 04)",
                              label: "Construction (17 09 04)",
                            },
                            {
                              value: "Offensive Waste (20 01 99)",
                              label: "Offensive Waste (20 01 99)",
                            },
                            {
                              value: "Organic Food Waste (20 01 08)",
                              label: "Organic Food Waste (20 01 08)",
                            },
                            {
                              value: "Gypsum (17 08 01)",
                              label: "Gypsum (17 08 01)",
                            },
                            {
                              value: "Waste Plastics (02 01 04)",
                              label: "Waste Plastics (02 01 04)",
                            },
                            {
                              value: "Garden Waste (20 02 01)",
                              label: "Garden Waste (20 02 01)",
                            },
                            {
                              value: "Mixed Municipal (20 03 01)",
                              label: "Mixed Municipal (20 03 01)",
                            },
                            {
                              value: "Mixed Metals (17 04 07)",
                              label: "Mixed Metals (17 04 07)",
                            },
                            {
                              value: "Other (Not listed)",
                              label: "Other (Not listed)",
                            },
                          ]}
                          onChange={(options: OptionsProps[]) => {
                            /**
                             * On change, update the field in the Formik context as normal.
                             */
                            if (options !== null && options.length > 0) {
                              setFieldValue("wasteTypes", options);

                              // Convert option array to string
                              const val = options
                                .map((item) => item.value)
                                .join(",");

                              // Assign string value to meta.wasteType field
                              setFieldValue("meta.wasteType", val);
                            } else {
                              setFieldValue("wasteTypes", "");
                              setFieldValue("meta.wasteType", "");
                            }
                          }}
                        />
                      )}
                    </Field>
                  )}
                  <Field
                    name="wasteDescription"
                    label={t(
                      "create.steps.collectionDetails.form.labels.wasteDescription",
                    )}
                  >
                    {({ field }) => (
                      <Input.Textarea
                        {...field}
                        placeholder={t(
                          "create.steps.collectionDetails.form.placeholders.wasteDescription",
                        )}
                        styles={{ maxHeight: 100, minHeight: 100 }}
                      />
                    )}
                  </Field>
                  <Field
                    name="accessInformation"
                    label={t(
                      "create.steps.collectionDetails.form.labels.accessInformation",
                    )}
                  >
                    {({ field }) => (
                      <Input.Textarea
                        {...field}
                        placeholder={t(
                          "create.steps.collectionDetails.form.placeholders.accessInformation",
                        )}
                        styles={{ maxHeight: 100, minHeight: 100 }}
                      />
                    )}
                  </Field>
                  <ImageUpload
                    defaultValues={formStateImages || []}
                    buttonClassName="ut-transition"
                    buttonText={t(
                      "create.steps.collectionDetails.form.buttons.uploadImages",
                    )}
                    onChange={(files: IImage[]) =>
                      setFieldValue("images", files)
                    }
                  />
                  {!!reportsMetaValidation?.length && (
                    <Box className="mt-4">
                      <h4 className="text-primary font-semibold">
                        {t(
                          "create.steps.collectionDetails.titles.additionalFields",
                        )}
                      </h4>
                      <Box className="grid grid-cols-2 gap-3">
                        {reportsMetaValidation.map((field, index: number) => (
                          <Box key={index}>
                            <Field
                              name={`reportsMeta.${field.name}`}
                              label={field.label}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  placeholder={field.label}
                                />
                              )}
                            </Field>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="my-10 flex flex-col items-center justify-center">
              <button
                data-testid="test-next"
                name="next"
                type="submit"
                className="btn-secondary hover:btn-secondary-hover transition ease-in duration-100"
                disabled={
                  !!deliveryOptions?.find(
                    ({ title }) => title === values.timeslotPreference,
                  )?.range?.length && !values.timeslot
                }
              >
                Next
              </button>
              {/* Display Form Fields Error */}
              {!!submitCount &&
                !isValid &&
                !!Object.keys(errors).length &&
                displayErrorMessage(
                  translate("create.errorFieldsRequired"),
                  ({ children }) => (
                    <Box className="flex flex-col items-center mt-4">
                      <Message
                        background={true}
                        type="error"
                        styles={{
                          textAlign: "center",
                        }}
                      >
                        {children}
                      </Message>
                    </Box>
                  ),
                )}
            </Box>
          </>
        );
      }}
    </Form>
  );
};
