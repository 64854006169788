import * as Yup from "yup";
import { translate } from "@clearabee/i18n";
import {
  stringRequired,
  optionField,
  requiredOption,
  validPostcode,
  email as emailField,
  emailRegExp,
  name,
  postcodeRegExp,
  fieldRequired,
  validInternationalPhoneNumber,
  optionalInternationalPhoneNumber,
} from "validation/common";
import { ISelectOption } from "../../core/select/simple";
import { ICreateJobFormState } from "./createJob";

//This regex to validate the three words separated by dots from https://developer.what3words.com/tutorial/detecting-if-text-is-in-the-format-of-a-3-word-address
const threeWordsReg =
  /^\/{0,}(?:[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+|[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+([\u0020\u00A0][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+){1,3}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+([\u0020\u00A0][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+){1,3}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+([\u0020\u00A0][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+){1,3})$/;
const emailRequiredWithoutSpecialCharacters = emailField.matches(
  emailRegExp,
  translate("portal.common.form.errors.validEmail"),
);
/**
 * When an email or phone number is provided, the other becomes optional.
 * If one of these is left blank, then the other becomes required.
 * One of the two must be entered, if the noContactDetails is set to false.
 */
const email = Yup.string()
  .email(translate("portal.common.form.errors.validEmail"))
  .notRequired()
  .when("phoneNumber", {
    is: (phone: any) => !phone || phone.length === 0,
    then: emailRequiredWithoutSpecialCharacters,
  });

const phone = optionalInternationalPhoneNumber.when("email", {
  is: (email: any) => !email || email.length === 0,
  then: validInternationalPhoneNumber,
});

const blankValues = {
  collectionDate: "",
  collectionAddress: {
    line1: "",
    line2: "",
    city: "",
    county: "",
    postcode: "",
    lat: null,
    lng: null,
  },
  meta: {
    poNumber: "",
    zendeskTicketId: "",
    wasteType: "",
    what3words: "",
  },
  customer: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  noContactDetails: false,
  wasteDescription: "",
  accessInformation: "",
  addressLookupPostcode: "",
  addressChoices: "",
  landType: "",
  images: [],
  basketToken: "",
  basketOrderRef: "",
  timeslot: "",
  timeslotPreference: "",
  isCustomTimeslot: false,
  customTimeslot: {
    startTime: "",
    endTime: "",
    charge: 0,
  },
  timeslotCharge: 0,
  sicCode: "",
  useAdhoc: false,
  bigchangeProps: {
    cust_RiskAddressIsCollectionAddress: false,
    cust_RiskPostcode: "",
    "cust_Is the customer vulnerable": false,
    "cust_VC Notes": "",
  },
};

export const getInitialValues = ({
  company,
}: {
  company: ISelectOption;
}): ICreateJobFormState => ({
  ...blankValues,
  company,
});

export const filterByCompany = Yup.object().shape({
  statusId: Yup.string().nullable(),
  companyId: stringRequired.nullable(),
});

export const filterByRef = Yup.object().shape({
  ref: stringRequired,
  postcode: stringRequired,
});

export const collectionDetailsInitialValues = {
  customer: blankValues.customer,
  meta: blankValues.meta,
  wasteDescription: blankValues.wasteDescription,
  accessInformation: blankValues.accessInformation,
  timeslot: "",
  timeslotPreference: "",
  isCustomTimeslot: false,
  customTimeslot: {
    startTime: "",
    endTime: "",
    charge: 0,
  },
  noContactDetails: blankValues.noContactDetails,
};

interface IGetCollectionDetailsSchemaOptions {
  isSkip: boolean;
  hasTimeslots: boolean;
  orderNumberRequired?: boolean;
  requirePhoneAndEmail?: boolean;
  orderNumberValidation: string;
  orderNumberValidationMessage: string;
  reportsMeta?: {
    name: string;
    label: string;
    required: boolean;
    validationMessage?: string;
    regex?: string;
  }[];
}

export const getCollectionDetailsSchema = ({
  isSkip,
  hasTimeslots,
  orderNumberRequired,
  requirePhoneAndEmail,
  orderNumberValidation,
  orderNumberValidationMessage,
  reportsMeta,
}: IGetCollectionDetailsSchemaOptions) => {
  const notAllowedPONumberValues = ["'", '"', "&"];
  const regex = new RegExp(orderNumberValidation);

  const reportsMetaObject: { [key: string]: any } = {};

  reportsMeta?.map((report) => {
    let validation = Yup.string();
    if (report.required) {
      validation = validation.required(fieldRequired);
    }

    if (report.regex) {
      // Check if report.regex is a string like "/pattern/flags"
      const match = report.regex.match(/^\/(.+)\/([gimuy]*)$/);
      let regex;
      if (match) {
        // if the string is in "/pattern/flags" format, extract pattern and flags
        const [, pattern, flags] = match;
        regex = new RegExp(pattern, flags);
      } else {
        // if the string is not in "/pattern/flags" format, use it directly
        regex = new RegExp(report.regex);
      }

      validation = validation.matches(regex, report.validationMessage);
    }

    reportsMetaObject[report.name] = validation;
  });

  return Yup.object().shape({
    noContactDetails: Yup.boolean(),
    customer: Yup.object({
      firstName: name,
      lastName: name,
      email: Yup.string(),
      phoneNumber: Yup.string(),
    }).when("noContactDetails", {
      is: false,
      then: Yup.object().shape(
        {
          firstName: stringRequired,
          lastName: stringRequired,
          email: requirePhoneAndEmail
            ? emailRequiredWithoutSpecialCharacters
            : email,
          phoneNumber: requirePhoneAndEmail
            ? validInternationalPhoneNumber
            : phone,
        },
        // An array of the possible optional fields must be added, to prevent cyclic dependency errors
        [["email", "phoneNumber"]],
      ),
    }),
    meta: Yup.object().shape({
      poNumber: orderNumberRequired
        ? Yup.string()
            .max(50, translate("portal.jobs.form.validation.fiftyMax"))
            .required(translate("portal.jobs.form.validation.requiredField"))
            .test(
              "no-spaces",
              translate("portal.jobs.form.validation.invalidChar"),
              async (val) =>
                val &&
                !notAllowedPONumberValues.some((item) => val.includes(item)),
            )
            .matches(regex, orderNumberValidationMessage ?? "Invalid field")
        : Yup.string()
            .max(50, translate("portal.jobs.form.validation.fiftyMax"))
            .test(
              "no-spaces",
              translate("portal.jobs.form.validation.invalidChar"),
              async (val) => {
                return val
                  ? !notAllowedPONumberValues.some((item) => val.includes(item))
                  : true;
              },
            )
            .matches(regex, orderNumberValidationMessage ?? "Invalid field"),
      zendeskTicketId: Yup.string(),
      wasteType: Yup.string(),
    }),
    wasteDescription: Yup.string(),
    accessInformation: Yup.string(),
    landType: isSkip ? stringRequired : Yup.string(),
    timeslotPreference: hasTimeslots
      ? Yup.string().when("isCustomTimeslot", {
          is: false,
          then: stringRequired,
          otherwise: Yup.string(),
        })
      : Yup.string(),
    timeslot: hasTimeslots
      ? Yup.string().when("timeslotPreference", {
          is: "Choose a 2 hour timeslot",
          then: stringRequired,
        })
      : Yup.string(),
    isCustomTimeslot: Yup.boolean(),
    customTimeslot: Yup.object().when("isCustomTimeslot", {
      is: true,
      then: Yup.object().shape({
        startTime: stringRequired,
        endTime: stringRequired,
        charge: Yup.number().test(
          "charge",
          "Charge is invalid",
          (charge) => Number(charge) >= 0,
        ),
      }),
      otherwise: Yup.object().shape({
        startTime: Yup.string(),
        endTime: Yup.string(),
        charge: Yup.number(),
      }),
    }),
    sicCode: Yup.string(),
    bigchangeProps: Yup.object().shape(
      {
        cust_RiskAddressIsCollectionAddress: Yup.boolean(),
        cust_RiskPostcode: Yup.string()
          .matches(postcodeRegExp, validPostcode)
          .when("cust_RiskAddressIsCollectionAddress", {
            is: true,
            then: stringRequired,
            otherwise: Yup.string(),
          }),
        "cust_Is the customer vulnerable": Yup.boolean(),
        "cust_VC Notes": Yup.string().when("cust_Is the customer vulnerable", {
          is: true,
          then: stringRequired,
          otherwise: Yup.string(),
        }),
      },
      [
        ["cust_RiskAddressIsCollectionAddress", "cust_RiskPostcode"],
        ["cust_Is the customer vulnerable", "cust_VC Notes"],
        ["timeslotPreference", "isCustomTimeslot"],
        ["isCustomTimeslot", "customTimeslot.startTime"],
        ["isCustomTimeslot", "customTimeslot.endTime"],
        ["isCustomTimeslot", "customTimeslot.charge"],
      ],
    ),
    reportsMeta: Yup.object().shape(reportsMetaObject),
    wasteTypes: Yup.array().nullable(),
  });
};

export interface IDateAddressFormValues {
  company: ICreateJobFormState["company"] | null;
  collectionDate: ICreateJobFormState["collectionDate"];
  collectionAddress: ICreateJobFormState["collectionAddress"];
  addressLookupPostcode: ICreateJobFormState["addressLookupPostcode"];
  addressChoices: ICreateJobFormState["addressChoices"];
  useAdhoc: boolean;
  meta: {
    what3words: ICreateJobFormState["meta"]["what3words"];
  };
}

export const getDateAddressInitialValues = (
  formState: ICreateJobFormState,
  canSelectCompany: boolean,
  isAdhocChecked: boolean,
): IDateAddressFormValues => ({
  company:
    !formState.collectionDate &&
    !formState.collectionAddress.postcode &&
    canSelectCompany
      ? null
      : formState.company,
  collectionDate: formState.collectionDate,
  collectionAddress: formState.collectionAddress,
  addressLookupPostcode: formState.collectionAddress.postcode,
  addressChoices: formState.addressChoices,
  useAdhoc: isAdhocChecked,
  meta: {
    what3words: formState.meta.what3words,
  },
});

export const getDateAddressSchema = (canSelectCompany: boolean) => {
  const manualAddressValidation = Yup.object().shape({
    line1: stringRequired,
    line2: Yup.string(),
    city: stringRequired.max(50, "Max 50 characters"),
    county: Yup.string(),
    postcode: stringRequired.min(3, validPostcode).max(11, validPostcode),
  });

  return Yup.object().shape({
    collectionDate: stringRequired,
    company: optionField.when("useAdhoc", {
      is: true,
      then: optionField.notRequired(),
      otherwise: canSelectCompany ? requiredOption : optionField,
    }),
    addressLookupPostcode: Yup.string().when("collectionAddress", {
      is: (values) => manualAddressValidation.isValid(values),
      then: Yup.string(),
      otherwise: stringRequired,
    }),
    useAdhoc: Yup.boolean(),
    collectionAddress: manualAddressValidation,
    meta: Yup.object().shape({
      what3words: Yup.string().matches(
        threeWordsReg,
        translate("portal.jobs.form.validation.validWhat3Words"),
      ),
    }),
  });
};
