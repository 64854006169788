import React, { useEffect } from "react";
import { useFormikContext, FormikProps, FormikValues } from "formik";

/**
 * Import components.
 */
import { Field, Input, UserStylesProps } from "@clearabee/ui-library";

/**
 * Interface.
 */

interface INoContactDetailsSwitch extends UserStylesProps {
  name: string;
  label: string;
}

export const NoContactDetailsSwitch: React.FC<INoContactDetailsSwitch> = ({
  name,
  label,
  styles: userStyles,
}) => {
  const { setFieldValue, values }: FormikProps<FormikValues> =
    useFormikContext();

  useEffect(() => {
    if (values[name]) {
      setFieldValue("customer.firstName", "");
      setFieldValue("customer.lastName", "");
      setFieldValue("customer.phoneNumber", "");
      setFieldValue("customer.email", "");
    }
  }, [values[name]]);

  return (
    <Field name={name} label={label} type="checkbox" styles={userStyles}>
      {({ field }) => <Input.Toggle {...field} />}
    </Field>
  );
};
